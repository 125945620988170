import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBmHBjNCWATc5vh80BONGjMT15Cwdhx4h4",
    authDomain: "tagtools-609e6.firebaseapp.com",
    projectId: "tagtools-609e6",
    storageBucket: "tagtools-609e6.appspot.com",
    messagingSenderId: "648393598955",
    appId: "1:648393598955:web:63369b92512a96816cdbae",
    measurementId: "G-LRZYM41QFZ"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app)

export default auth;