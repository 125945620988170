import { useState } from "react";
import styled from "styled-components";
import { Download } from "./Download";
import { Format } from "./Format";
import { UploadArea } from "./Upload";

const TranscriberItems = styled.div`
  display: grid;
  grid-template-columns: 495px 495px 495px;
  grid-gap: 40px;
  justify-content: center;
  margin-top: 42px;
`;

const TranscriberText = styled.div`
  grid-column-start: 1;
  justify-self: start;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
`;

const DownloadButton = styled.div`
  grid-column-start: 3;
  justify-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 217px;
  height: 39px;
  background: #435bff;
  box-shadow: 8px 8px 37px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  color: #ffffff;
  font-family: IBM Plex Mono;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.8;
  }
`;

export type StepsStatus = {
  first: boolean;
  second: boolean;
  third: boolean;
};

export type StepProps = {
  stepsStatus: StepsStatus;
  setStepsStatus: (status: StepsStatus) => void;
};

export const Transcriber = () => {
  const [stepsStatus, setStepsStatus] = useState<StepsStatus>({
    first: false,
    second: false,
    third: false,
  });
  const [uploadedFiles, setUploadFiles] = useState<any[]>([]);
  return (
    <TranscriberItems>
      <TranscriberText>OneAi Transcriber</TranscriberText>
      <DownloadButton
        onClick={() => {
          window.open(
            "https://drive.google.com/drive/u/2/folders/1alb8k2nfda_LoZ_CL0G5XHHnPdKL-bBN"
          );
        }}
      >Open transcribed files</DownloadButton>
      <UploadArea
        stepsStatus={stepsStatus}
        setStepsStatus={setStepsStatus}
        setUploadFiles={setUploadFiles}
        uploadedFiles={uploadedFiles}
      />
      <Format
        stepsStatus={stepsStatus}
        setStepsStatus={setStepsStatus}
        uploadedFiles={uploadedFiles}
      />
      <Download stepsStatus={stepsStatus} setStepsStatus={setStepsStatus} />
    </TranscriberItems>
  );
};
