import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Transcriber } from "components/Transcriber";
import SignIn from "screens/sign";
import AuthProvider from "components/AuthProvider";
import ProtectedRoute from "components/ProtectedRoute";
import { Header } from "components/Header";
import "./App.css";
import "assets/fonts/poppins.css";
import "assets/fonts/ibmplexmono.css";

function App() {
  return (
    <AuthProvider>
      <Header />
      <Router>
        <Routes>
          <Route
            path="/"
            element={<ProtectedRoute component={Transcriber} />}
          />
          <Route path="/signin" element={<SignIn />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
