import styled from "styled-components";
import logo from "assets/logo.svg";

const HeaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  border-bottom: 1px solid #D8DEE7;
`;

const Logo = styled.div`
  width: 155px;
  height: 37px;
  background: url(${logo});
`;
export const Header = () => (
  <HeaderContent>
    <Logo />
  </HeaderContent>
);
