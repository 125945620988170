import styled, { keyframes } from "styled-components";
import { useState } from "react";
import { FormatFile } from "utils";
import { Button, CircleStatus, MarkIcon } from "./styledElements";
import { StepProps } from ".";
import { useAuth } from "components/AuthProvider";

const Area = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 365px;
  width: 495px;
  background: #ffffff;
  border-radius: 5px;
`;

const FormatText = styled.div`
  display: flex;
  justify-content: center;
  margin: 80px 0 28px;
  font-size: 18px;
  font-weight: bold;
  color: ${(props: { disabled: boolean }) =>
    props.disabled ? "#D0D0D0" : "#202020"};
`;

const Dropdown = styled.div`
  width: 256px;
  height: 47px;
  font-family: IBM Plex Mono;
  font-weight: 500;
  font-size: 14px;
  line-height: 107.8%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: ${(props: { open: boolean }) =>
    props.open ? "5px 5px 0px 0px" : "5px"};
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 0 21px;
  user-select: none;
  cursor: pointer;
  background: #ffffff;
  color: ${(props: { disabled: boolean }) =>
    props.disabled ? "#E0E0E0" : "#202020"};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Preloader = styled.div`
  border-radius: 50%;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  width: 10px;
  height: 10px;
  animation: ${rotate} 2s linear infinite;
`;

const DropdownTitle = styled.div`
  font-family: IBM Plex Mono;
  font-weight: 500;
  font-size: 14px;
  line-height: 107.8%;
  letter-spacing: -0.03em;
  user-select: none;
`;

const DropdownBody = styled.div`
  border: 1px solid #d2d2d2;
  margin: -1px -1px 0 -1px;
  border-top: 0;
  background: #ffffff;
`;

const DropdownItem = styled.div`
  height: 40px;
  padding-left: 21px;
  font-family: IBM Plex Mono;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #202020;
  cursor: pointer;
  user-select: none;
  :hover {
    background: #f3f5f9;
  }
`;
const FileStatus = styled.div`
  display: flex;
  align-items: center;
`;
const FileName = styled.div`
  margin-right: 5px;
`;
const CheckIcon = styled.div`
  color: #11d100;
`;
const FileStatuses = styled.div`
  margin-top: 10px;
  max-height: 80px;
  overflow-y: auto;
  width: 200px;
`;

type UploadFiles = {
  uploadedFiles: any[];
};

export const Format = ({
  stepsStatus,
  setStepsStatus,
  uploadedFiles,
}: StepProps & UploadFiles) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("Target Format");
  const isDisabled = !stepsStatus.first;
  const [filesProgress, setFilesProgress] = useState<any>([]);

  const { currentUser } = useAuth();
  const mapTypeApi = {
    "text/csv": "/api/parsecsv",
    "audio/wav": "/api/uploadvoicefile",
    "application/json": "/api/parsejson",
    "text/plain": "/api/parsejson",
  };
  const handleSubmit = async () => {
    setIsLoader(true);
    const type = uploadedFiles[0].type;
    const statuses = uploadedFiles.map((file) => {
      return { name: file.name, progress: "in-progress" };
    });
    setFilesProgress(statuses);

    uploadedFiles.forEach((file, i) => {
      return new Promise((res) => {
        const formData = new FormData();
        /* @ts-ignore:next-line */
        formData.append(`file-${i}`, file);
        try {
          // @ts-ignore
          fetch(mapTypeApi[type], {
            method: "POST",
            // @ts-ignore
            body: formData,
            headers: {
              Authorization: `Bearer ${currentUser.accessToken}`,
              convertFormat: dropdownValue,
            },
          }).then(() => {
            statuses.splice(i, 1, { name: file.name, progress: "done" });
            setFilesProgress([...statuses]);
            if (i === statuses.length - 1) {
              setStepsStatus({ ...stepsStatus, second: true });
              setIsLoader(false);
            }
          });
        } catch (error) {
          alert(error);
        }
      });
    });
  };
  return (
    <Area>
      <CircleStatus>{stepsStatus.second ? <MarkIcon /> : 2}</CircleStatus>
      <FormatText disabled={isDisabled}>Choose a format</FormatText>
      <Dropdown
        open={isDropdownOpen}
        onClick={() => {
          if (!isDisabled) {
            setIsDropdownOpen(!isDropdownOpen);
          }
        }}
      >
        <DropdownHeader disabled={isDisabled}>
          <DropdownTitle>{dropdownValue}</DropdownTitle>
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d={isDropdownOpen ? "M9 5L5 1L1 5" : "M1 1L5 5L9 1"}
              stroke={isDisabled ? "#E0E0E0" : "#435BFF"}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </DropdownHeader>
        {isDropdownOpen && (
          <DropdownBody
            onClick={(e) => {
              const target = e.target as Element;
              setDropdownValue(target.innerHTML);
            }}
          >
            {Object.values(FormatFile)
              .filter((el) => el !== dropdownValue)
              .map((format) => (
                <DropdownItem key={format}>{format}</DropdownItem>
              ))}
          </DropdownBody>
        )}
      </Dropdown>
      {!isDropdownOpen && (
        <Button
          marginTop="43px"
          disabled={isDisabled}
          onClick={() => {
            if (!isDisabled) {
              handleSubmit();
            }
          }}
        >
          {isLoader ? <Preloader /> : "Convert"}
        </Button>
      )}
      {!isDropdownOpen && (
        <FileStatuses>
          {filesProgress.map((file: any) => {
            return (
              <FileStatus>
                <FileName>{file.name}</FileName>
                {file.progress === "in-progress" ? (
                  <Preloader />
                ) : (
                  <CheckIcon>🗸</CheckIcon>
                )}
              </FileStatus>
            );
          })}
        </FileStatuses>
      )}
    </Area>
  );
};
