import CheckIcon from "assets/check.svg";
import styled from "styled-components";

type ButtonProps = {
  marginTop?: string;
  disabled?: boolean;
};

export const CircleStatus = styled.div`
  position: absolute;
  top: -25px;
  left: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #ffffff;
  box-shadow: 8px 8px 37px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
`;

export const MarkIcon = styled.div`
  width: 11px;
  height: 11px;
  background: url(${CheckIcon});
  background-size: contain;
`;

export const Button = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 154px;
  height: 39px;
  margin-top: ${(props: ButtonProps) =>
    props.marginTop ? props.marginTop : 0};
  background: ${(props: ButtonProps) =>
    props.disabled ? "#D0D0D0" : "#435bff"};
  box-shadow: 8px 8px 37px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  color: #ffffff;
  font-family: IBM Plex Mono;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  :hover {
    opacity: 0.8;
  }
`;
