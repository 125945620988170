import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

const ProtectedRoute = ({ component: RouteComponent, ...rest }) => {
    const {currentUser} = useContext(AuthContext);
    if (currentUser) {
        return <RouteComponent {...rest} />;
    }
    return <Navigate to="/signin" {...rest} />;
};

export default ProtectedRoute;