import styled from "styled-components";
import ArrowUpload from "assets/arrowUpload.svg";
import removeIcon from "assets/removeIcon.svg";

import { Button, CircleStatus, MarkIcon } from "./styledElements";
import { StepProps } from ".";

const Area = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 365px;
  width: 495px;
  background: rgba(13, 221, 221, 0.02);
  border: 1px dashed #0ddddd;
  box-sizing: border-box;
  border-radius: 5px;
`;

const Upload = styled.label`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HiddenInput = styled.input`
  display: none;
`;

const UploadIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  min-height: 84px;
  margin-top: 60px;
  background: #d1f1f5;
  border-radius: 5px;
`;

const UploadArrow = styled.div`
  width: 36px;
  height: 36px;
  background: url(${ArrowUpload});
`;

const UploadText = styled.div`
  display: flex;
  justify-content: center;
  margin: 18px 0 34px;
  font-weight: bold;
`;
const ListOfFiles = styled.div`
  margin-top: 10px;
  max-height: 80px;
  overflow: auto;
`;

const File = styled.div`
  display: flex;
  justify-content: space-between;
  width: 240px;
  margin-top: 5px;
`;

const FileName = styled.div`
  font-size: 14px;
`;

const RemoveIcon = styled.div`
  height: 24px;
  width: 24px;
  background-image: url(${removeIcon});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  user-select: none;
`;

const allowedTypesForFiles = {
  "text/csv": "text/csv",
  "audio/wav": "audio/wav",
  "application/json": "application/json",
};

type UploadProps = {
  uploadedFiles: any[];
  setUploadFiles: (file: any) => void;
};

export const UploadArea = ({
  stepsStatus,
  setStepsStatus,
  setUploadFiles,
  uploadedFiles,
}: StepProps & UploadProps) => {
  const uploadFile = (files: any) => {
    if (files.length > 1) {
      const currentType = files[0].type;
      for (let i = 0; i < files.length; i++) {
        const { type } = files[i];
        // @ts-ignore
        if (allowedTypesForFiles[type]) {
          // @ts-ignore
          if (currentType !== allowedTypesForFiles[type]) {
            alert("You sould to add files with the same type");
            return false;
          }
        } else {
          alert(
            'You should to add files with "text/csv" / "audio/wav" / "application/json"'
          );
          return false;
        }
      }
    }

    setUploadFiles([...files]);
    setStepsStatus({ first: true, second: false, third: false });
  };

  return (
    <Area
      onDrop={(e: any) => {
        e.nativeEvent.preventDefault();
        const { files } = e.nativeEvent.dataTransfer;
        uploadFile(files);
      }}
      onDragOver={(e) => e.preventDefault()}
    >
      <HiddenInput
        onChange={(e) => {
          const { files } = e.target;
          uploadFile(files);
        }}
        multiple
        id="input"
        name="file"
        type="file"
        className="csv-input__file-input"
      />
      <CircleStatus>{stepsStatus.first ? <MarkIcon /> : 1}</CircleStatus>
      <UploadIcon>
        <UploadArrow />
      </UploadIcon>
      <UploadText>Upload your file</UploadText>
      <Upload htmlFor="input">
        <Button>Select File</Button>
      </Upload>
      {!!uploadedFiles.length && (
        <ListOfFiles>
          {uploadedFiles.map((file) => (
            <File key={file.size}>
              <FileName>{file.name}</FileName>
              <RemoveIcon
                onClick={(e) => {
                  e.stopPropagation();
                  if (uploadedFiles.length === 1) {
                    setUploadFiles([]);
                    setStepsStatus({ ...stepsStatus, first: false });
                    return;
                  }
                  setUploadFiles(
                    uploadedFiles.filter(
                      (oldFile) => oldFile.name !== file.name
                    )
                  );
                }}
              />
            </File>
          ))}
        </ListOfFiles>
      )}
    </Area>
  );
};
