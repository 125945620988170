import styled from "styled-components";
import SuccessIcon from "assets/successIconDownload.svg";
import DisabledIcon from "assets/disabledDownloadIcon.svg";
import { Button, CircleStatus, MarkIcon } from "./styledElements";
import { StepProps } from ".";

const Area = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 365px;
  width: 495px;
  background: #ffffff;
  border-radius: 5px;
`;

const TextBlock = styled.div`
  height: 60px;
`;

const Icon = styled.div`
  width: 117px;
  height: 117px;
  margin: ${(props: { isDisabled: boolean }) =>
    props.isDisabled ? "75px 0 14px" : "20px 0 14px"};
  background: url(${(props: { isDisabled: boolean }) =>
    props.isDisabled ? DisabledIcon : SuccessIcon});
`;

const SuccessfullText = styled.div`
  display: flex;
  justify-content: center;
  width: 195px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;

export const Download = ({ stepsStatus, setStepsStatus }: StepProps) => {
  const isDisabled = !stepsStatus.first || !stepsStatus.second;
  return (
    <Area>
      <CircleStatus>{stepsStatus.third ? <MarkIcon /> : 3}</CircleStatus>
      <Icon isDisabled={isDisabled} />
      {!isDisabled && (
        <TextBlock>
          <SuccessfullText>Conversion</SuccessfullText>
          <SuccessfullText>finished successfully</SuccessfullText>
        </TextBlock>
      )}
      <Button
        marginTop="20px"
        disabled={isDisabled}
        onClick={() => {
          if (!isDisabled) {
            window.open(
              "https://drive.google.com/drive/u/2/folders/1alb8k2nfda_LoZ_CL0G5XHHnPdKL-bBN"
            );

            setStepsStatus({ ...stepsStatus, third: true });
          }
        }}
      >
        Download file
      </Button>
    </Area>
  );
};
