import React, { useEffect, useState, useContext } from 'react';
import { signOut } from 'firebase/auth';
import auth from 'helpers/firebaseInit';

export const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
        if (user && /oneai.com/.test(user.email)) {
            await setCurrentUser(user);
        } else {
            await auth.signOut();
        }
    })
    
        return unsubscribe;
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
