import React from 'react';
import styled from 'styled-components'; 
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from "react-router-dom"
import auth from '../helpers/firebaseInit';

const provider = new GoogleAuthProvider();

const SignInWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 93px);
`;

const SigninButton = styled.button`
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  background: #2299bf;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  font-size: 20px;
  padding: 20px;
  margin: 5px;
  width: 15rem;

  &:active {
    background: #36b0d7;
  }
`;

const SignIn = () => {
  const navigate = useNavigate()

  const handleOpenSignIn = () => {
      /* @ts-ignore:next-line */
      signInWithPopup(auth, provider).then(result => {
        if (/oneai.com/.test(result.user.email)) {
          navigate('/', { replace: true });
        }
      })
  }
  return (
    <SignInWrapper>
      <SigninButton onClick={handleOpenSignIn}>Login</SigninButton>
    </SignInWrapper>);
};

export default SignIn;